import React from "react"
import styled from "styled-components"

import FlyHookOne from "../elements/FlyHookOne"
import FlyHookTwo from "../elements/FlyHookTwo"
import FlyHookThree from "../elements/FlyHookThree"
import FlyHookFour from "../elements/FlyHookFour"
import FlyHookFive from "../elements/FlyHookFive"
import FlyHookSix from "../elements/FlyHookSix"

const FlyPickerStyles = styled.div`
  .fly-icon {
    position: absolute;
    top: -12.5rem;
    right: 0;
    left: 0;
    margin: auto;
    z-index: -1;
  }

  .fly-icon-one {
    width: calc(60rem / 4);
    height: calc(54.5rem / 4);
  }

  .fly-icon-two {
    width: calc(60rem / 4);
    height: calc(70rem / 4);
  }

  .fly-icon-three {
    width: calc(60rem / 4);
    height: calc(55.5rem / 4);
  }

  .fly-icon-four {
    width: calc(80rem / 4.75);
    height: calc(52.4rem / 4.75);
  }

  .fly-icon-five {
    width: calc(80rem / 4.75);
    height: calc(61.8rem / 4.75);
  }

  .fly-icon-six {
    width: calc(80rem / 4.75);
    height: calc(72.9rem / 4.75);
  }
`

const FlyPicker = ({ info }) => {
  let flyIcon = false
  if (info === "one") {
    flyIcon = (
      <div className="fly-icon fly-icon-one">
        <FlyHookOne />
      </div>
    )
  } else if (info === "two") {
    flyIcon = (
      <div className="fly-icon fly-icon-two">
        <FlyHookTwo />
      </div>
    )
  } else if (info === "three") {
    flyIcon = (
      <div className="fly-icon fly-icon-three">
        <FlyHookThree />
      </div>
    )
  } else if (info === "four") {
    flyIcon = (
      <div className="fly-icon fly-icon-four">
        <FlyHookFour />
      </div>
    )
  } else if (info === "five") {
    flyIcon = (
      <div className="fly-icon fly-icon-five">
        <FlyHookFive />
      </div>
    )
  } else if (info === "six") {
    flyIcon = (
      <div className="fly-icon fly-icon-six">
        <FlyHookSix />
      </div>
    )
  }

  return <>{flyIcon && <FlyPickerStyles>{flyIcon}</FlyPickerStyles>}</>
}

export default FlyPicker
