import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { standardWrapper, headlineThree, colors } from "../Utilities"
import FlyPicker from "./FlyPicker"
import LinePicker from "./LinePicker"

const SimpleContentStyled = styled.div`
  position: relative;

  .scfp-wrapper {
    ${standardWrapper};
  }

  .scfp-content {
    position: relative;
    width: 100%;
    max-width: 85rem;
    margin: 15rem auto 2rem;
    padding: 2rem;
    text-align: center;

    &__container {
      p {
        ${headlineThree};
        margin-bottom: 2rem;

        color: ${colors.colorSecondary};
        text-transform: uppercase;
        line-height: 1.42;
      }
    }

    &__logos {
      ${standardWrapper};
      margin: 0;
      margin-top: 4rem;
      padding: 0;

      &--item {
        width: calc(50% - 4rem);
        margin: 2rem;
        @media (min-width: 768px) {
          width: calc(20% - 4rem);
          padding: 1rem;
        }
      }
    }
  }
`

const SimpleContent = ({ data }) => {
  return (
    <SimpleContentStyled>
      <div className="scfp-wrapper">
        <div className="scfp-content">
          <div className="scfp-content__container">
            {data.contentPoints.map((point, index) => {
              return <p key={index}>{point.point}</p>
            })}
          </div>
          <div className="scfp-content__logos">
            {data.contentLogos.map((logo, index) => {
              return (
                <div className="scfp-content__logos--item" key={index}>
                  <a target="_blank" rel="noopener noreferrer" href={logo.link}>
                    <Img
                      fluid={logo.logo.localFile.childImageSharp.fluid}
                      alt={logo.logo.alt_text}
                    />
                  </a>
                </div>
              )
            })}
          </div>
          <FlyPicker info={data.flyPicker} />
        </div>
      </div>
      <LinePicker info="two" />
    </SimpleContentStyled>
  )
}

export default SimpleContent
