import React, { Component } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { TimelineMax, Power0, CSSPlugin, AttrPlugin } from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"

import { medWrapper, headlineOne, bodyCopy, colors } from "../Utilities"
import FlyHookTwo from "../elements/FlyHookTwo"
import FlyLineFive from "../elements/FlyLineFive"

const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const SideBySideSection = styled.section`
  position: relative;
  padding: 5rem 0;

  .sbs-wrapper {
    ${medWrapper};
  }

  .sbs-left-side {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }

    .sbs-left-content {
      margin-top: 1rem;
      padding: 5rem;
      background: ${colors.colorTertiary};

      @media (min-width: 1025px) {
        padding: 7.5rem;
      }

      p {
        ${bodyCopy};
        color: ${colors.white};

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .sbs-right-side {
    width: 100%;
    margin-top: 7.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: 0;
      margin-left: 4rem;
    }

    .sbs-right-img,
    .sbs-main-content {
      position: relative;
      z-index: 10;
    }

    .sbs-right-title {
      position: relative;
      z-index: 1;
      .fly-line-hook-container {
        position: absolute;
        top: 0rem;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .fly-hook-icon {
        position: absolute;
        top: -5rem;
        left: 50%;
        width: calc(60rem / 4.75);
        height: calc(70rem / 4.75);
        z-index: -1;

        @media (min-width: 768px) {
          top: -2.5rem;
        }
      }
    }

    h2 {
      ${headlineOne};
      width: 50%;
      margin-top: 0;
      margin-bottom: 1rem;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
    }
  }

  .fly-line-icon {
    position: absolute;
    top: -5rem;
    left: 67.5%;
    width: calc(117rem / 1);
    height: calc(59.7rem / 1);
    transform: rotateX(0) rotateY(180deg);
    z-index: -1;

    @media (min-width: 768px) {
      top: -5rem;
      left: 61.5%;
      width: calc(117rem / 1);
      height: calc(59.7rem / 1);
    }
  }
`

class SideBySide extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const lineTl = new TimelineMax()
    const controller = new ScrollMagic.Controller()

    lineTl
      .add("start")
      .fromTo(
        "#flyLineHook",
        1,
        { y: "75%", scale: 1, ease: Power0.easeNone },
        { y: "0%", scale: 1, ease: Power0.easeNone },
        "start"
      )

    new ScrollMagic.Scene({
      triggerElement: "#sectionTrigger",
      duration: "175%",
      offset: 0,
      triggerHook: 1,
    })
      .setTween(lineTl)
      .addTo(controller)
    // .addIndicators()
  }

  render() {
    const { data } = this.props
    return (
      <SideBySideSection>
        <div className="sbs-wrapper">
          <div className="sbs-left-side">
            <div>
              <Img
                fluid={data.imgTopLeft.localFile.childImageSharp.fluid}
                alt={data.imgTopLeft.alt_text}
              />
            </div>
            <div
              className="sbs-left-content"
              dangerouslySetInnerHTML={{ __html: data.contentLeft }}
            />
          </div>

          <div className="sbs-right-side">
            <div id="sectionTrigger" className="sbs-right-title">
              <h2>{data.titleRight}</h2>
              <div id="flyLineHook" className="fly-line-hook-container">
                <div id="flyHook" className="fly-hook-icon">
                  <FlyHookTwo />
                </div>
                <div id="flyLine" className="fly-line-icon">
                  <FlyLineFive />
                </div>
              </div>
            </div>
            <div
              className="sbs-main-content"
              dangerouslySetInnerHTML={{ __html: data.contentRight }}
            />
            <div className="sbs-right-img">
              <Img
                fluid={data.imgBotRight.localFile.childImageSharp.fluid}
                alt={data.imgTopLeft.alt_text}
              />
            </div>
          </div>
        </div>
      </SideBySideSection>
    )
  }
}

export default SideBySide
