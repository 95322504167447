import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import SideBySide from "../components/SideBySide"
import CallOutBackground from "../components/CallOutBackground"
import SimpleContent from "../components/SimpleContent"
import TestimonialsSlider from "../components/TestimonialsSlider"

const About = (props) => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const sideBySideData = {}
  sideBySideData.imgTopLeft = props.data.page.acf._dow_sbs_image_top_left
  sideBySideData.imgBotRight = props.data.page.acf._dow_sbs_image_bottom_right
  sideBySideData.contentLeft = props.data.page.acf._dow_sbs_content_left
  sideBySideData.titleRight = props.data.page.acf._dow_sbs_title_right
  sideBySideData.contentRight = props.data.page.acf._dow_sbs_content_right

  const dowCallout = {}
  dowCallout.quote = props.data.page.acf._dow_background_quote
  dowCallout.imgTop = props.data.page.acf._dow_callout_img_top
  dowCallout.title = props.data.page.acf._dow_callout_title
  dowCallout.content = props.data.page.acf._dow_callout_content
  dowCallout.heroImg = props.data.page.acf._dow_callout_hero_img
  dowCallout.heroTitle = props.data.page.acf._dow_callout_hero_title
  dowCallout.heroContent = props.data.page.acf._dow_callout_hero_content
  dowCallout.heroIcon = props.data.page.acf._dow_callout_icon

  const simpleContent = {}
  simpleContent.flyPicker = props.data.page.acf._dow_scfp_fly_picker
  simpleContent.contentPoints = props.data.page.acf._dow_scfp_content_points
  simpleContent.contentLogos = props.data.page.acf._dow_scfp_content_logos

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      <SideBySide data={sideBySideData} />
      <CallOutBackground data={dowCallout} />
      <SimpleContent data={simpleContent} />
      <TestimonialsSlider />
    </Layout>
  )
}

export const query = graphql`
  query aboutPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_sbs_image_top_left {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_sbs_image_bottom_right {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_sbs_content_left
        _dow_sbs_title_right
        _dow_sbs_content_right

        _dow_callout_img_top {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _dow_background_quote
        _dow_callout_title
        _dow_callout_content
        _dow_callout_hero_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _dow_callout_hero_title
        _dow_callout_hero_content
        _dow_callout_icon

        _dow_scfp_fly_picker
        _dow_scfp_content_points {
          point
        }
        _dow_scfp_content_logos {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          link
        }
      }
    }
  }
`

export default About
