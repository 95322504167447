import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import { headlineOne, colors, bodyCopy, fontSizer, fonts } from "../Utilities"
import FlyRod from "../elements/FlyRod"

const getData = graphql`
  query testimonials {
    tests: allWordpressWpTestimonials {
      edges {
        node {
          wordpress_id
          acf {
            _dow_name
            _dow_content
            _dow_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const TestimonialsSection = styled.section`
  margin: 5rem 0;
  padding-top: 15rem;

  @media (min-width: 768px) {
    padding-top: 20rem;
  }

  @media (min-width: 1025px) {
    padding-top: 25rem;
  }

  .test-title {
    position: relative;
    margin-left: 14rem;

    h2 {
      ${headlineOne};
      margin: 0;
      color: ${colors.colorPrimary};
      text-transform: uppercase;

      span {
        display: block;
      }
    }

    .test-fly-rod {
      position: absolute;
      right: 0;
      bottom: 7.5rem;
      width: calc(100rem / 5);
      height: calc(75.8rem / 5);
      z-index: -1;

      @media (min-width: 768px) {
        top: auto;
        bottom: 0;
        width: calc(100rem / 3);
        height: calc(75.8rem / 3);
      }

      @media (min-width: 1025px) {
        width: calc(100rem / 2.25);
        height: calc(75.8rem / 2.25);
      }
    }
  }

  .test-slider {
    position: relative;

    @media (min-width: 768px) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10rem;
        background: ${colors.white};
        content: "";
        z-index: 500;
      }
    }

    .slick-arrow {
      display: none;

      @media (min-width: 768px) {
        display: block;
        position: absolute;
        left: 5rem;
        z-index: 501;
      }

      &::before {
        font-family: ${fonts.fontAwesome};
        font-weight: 100;
        color: ${colors.colorSecondary};
      }
    }
    .slick-prev {
      top: 50%;
      &::before {
        content: "\f054";
      }
    }

    .slick-next {
      top: 55%;
      &::before {
        content: "\f053";
      }
    }

    .slick-track {
      .slick-slide {
        padding: 3rem;
        height: 100%;
        min-height: 60rem;

        @media (min-width: 1025px) {
          min-height: 65rem;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`

const TestimonialCard = styled.div`
  padding: 3rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-height: 60rem;

  @media(min-width: 1025px) {
    min-height: 65rem;
  }

  &:focus {
    outline: none;
  }
  .test-image {
    width: 14rem;
    height: 14rem;
    margin: 2rem auto;
    border-radius: 50%;
    overflow: hidden;
  }

  .test-content,
  .test-name {
    p {
      ${bodyCopy};
      text-align: center;
    }
  }

  .test-name {
    p {
      ${fontSizer(1.6, 1.8, 76.8, 160, 1.8)}
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }
  }

  .test-content {
    position: relative;

    &::before {
      position: absolute;
      top: 22rem;
      left: 0;
      width: 100%;
      color: ${colors.colorSecondary};
      font-size: 60rem;
      line-height: 0.08;
      letter-spacing: 15px;
      text-align: center;
      content: "“";
      opacity: 0.13;     
      z-index: 1;
    }

    @media (min-width: 768px) {
      &::before {
        font-size: 40rem;
      }
    }

    @media (min-width: 1025px) {
      &::before {
        font-size: 40rem;
      }
    }

    @media (min-width: 1250px) {
      &::before {
        font-size: 50rem;
      }
    }

    @media (min-width: 1400px) {
      &::before {
        font-size: 60rem;
      }
    }

    p {
      position: relative;
      ${fontSizer(1.4, 1.6, 76.8, 160, 1.8)}
      color: ${colors.black};
      font-style: italic;
      z-index: 5;
    }
  }
`

const TestimonialsSlider = () => {
  const data = useStaticQuery(getData)
  const {
    tests: { edges: tests },
  } = data

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    draggable: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 10000,
    centerMode: true,
    centerPadding: "100px",
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  }

  return (
    <TestimonialsSection>
      <div className="test-title">
        <h2>
          Our Clients <span>Are Hooked!</span>
        </h2>
        <div className="test-fly-rod">
          <FlyRod />
        </div>
      </div>

      <Slider {...settings} className="test-slider">
        {tests.length > 0 &&
          tests.map(test => {
            return (
              <TestimonialCard key={test.node.wordpress_id}>
                <div className="test-image">
                  <Img
                    fluid={
                      test.node.acf._dow_image.localFile.childImageSharp.fluid
                    }
                    alt={test.node.acf._dow_image.alt_text}
                  />
                </div>
                <div className="test-name">
                  <p>{test.node.acf._dow_name}</p>
                </div>
                <div
                  className="test-content"
                  dangerouslySetInnerHTML={{
                    __html: test.node.acf._dow_content,
                  }}
                />
              </TestimonialCard>
            )
          })}
      </Slider>
    </TestimonialsSection>
  )
}

export default TestimonialsSlider
