import React, { Component } from "react"
import Img from "gatsby-image"
import ImgBg from "gatsby-background-image"
import styled from "styled-components"
import { TimelineMax, Power0, CSSPlugin, AttrPlugin } from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"
import debugAddIndicators from "debug.addIndicators"

import {
  medWrapper,
  headlineOne,
  bodyCopy,
  colors,
  headlineFive,
  fontSizer,
} from "../Utilities"

import MedicineIcon from "../elements/MedicineIcon"
import FlyHookSix from "../elements/FlyHookSix"

const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const CallOutBackgroundSection = styled.section`
  overflow: hidden;
  
  .co-wrapper {
    ${medWrapper};
    position: relative;
    align-items: center;

    @media (min-width: 1600px) {
      max-width: 135rem;
    }
  }

  .co-image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }
  }

  .co-content {
    width: 100%;
    padding: 7.5rem 0;

    @media (min-width: 768px) {
      width: calc(100%);
      padding: 10rem 0 20rem;
    }

    &__title {
      width: 100%;
      max-width: 100rem;
      text-align: center;
      margin: auto;

      h2 {
        ${headlineOne};
        margin: 0;
        color: ${colors.colorPrimary};
      }
    }

    &__body {
      p {
        ${bodyCopy};
      }
    }

    .co-background {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      p {
        ${headlineFive};
        ${fontSizer(24, 50, 76.8, 160, 8)};
      }
    }
  }

  .co-topsec {
    position: relative;
    width: 100%;
    height: 40rem;

    &__fly {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 10rem;
      left: 0;
      width: calc(80rem / 2);
      height: calc(72.9rem / 2);
      margin: auto;
      z-index: -1;
    }

    &__callout {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 1;

      p {
        ${headlineFive};
        ${fontSizer(24, 50, 76.8, 160, 8)};
        width: 100%;

        span {
          display: inline-block;
        }
      }
    }
  }

  .co-herosec {
    position: relative;
    padding: 40rem 0 15rem;

    &__icon {
      position: absolute;
      top: -27.5rem;
      right: 0;
      left: 0;
      width: calc(45.5rem / 4);
      height: calc(94.1rem / 4);
      margin: auto;
      z-index: 100;
    }

    &__heroimg {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__content {
      position: relative;
      max-width: 100rem;
      margin: 2rem auto;
      padding: 0 2rem;
      z-index: 10;

      &--title {
        h2 {
          ${headlineOne};
          margin: 0;
          color: ${colors.white};
          text-align: center;
          text-transform: uppercase;
        }
      }

      &--body {
        p {
          ${bodyCopy};
          color: ${colors.white};
        }
      }
    }

    &__callout {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background: transparent;
      z-index: 5;

      p {
        text-align: center;

        span {
          ${headlineFive};
          ${fontSizer(24, 50, 76.8, 160, 8)};
          display: inline-block;
          opacity: 0.25;
          line-height: 1;
        }
      }
    }
  }
`

class CallOutBackground extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const driftController = new ScrollMagic.Controller()
    const outController = new ScrollMagic.Controller()
    const westController = new ScrollMagic.Controller()

    const driftTL = new TimelineMax()
    const outTL = new TimelineMax()
    const westTL = new TimelineMax()

    driftTL.fromTo(
      "#letter-drift",
      1.5,
      { x: "-100%", ease: Power0.easeNone },
      { x: "0%", ease: Power0.easeNone }
    )

    outTL.fromTo(
      "#letter-out",
      1.5,
      { x: "100%", ease: Power0.easeNone },
      { x: "0%", ease: Power0.easeNone }
    )

    westTL.fromTo(
      "#letter-west",
      1.5,
      { x: "-100%", ease: Power0.easeNone },
      { x: "0%", ease: Power0.easeNone }
    )

    new ScrollMagic.Scene({
      triggerElement: "#letter-drift-container",
      duration: 0,
      reverse: false,
      offset: -200,
      triggerHook: 1,
    })
      .setTween(driftTL)
      .addTo(driftController)
    //.addIndicators()

    new ScrollMagic.Scene({
      triggerElement: "#letter-out-container",
      duration: 0,
      reverse: false,
      offset: -200,
      triggerHook: 1,
    })
      .setTween(outTL)
      .addTo(outController)
    //.addIndicators()

    new ScrollMagic.Scene({
      triggerElement: "#letter-west-container",
      duration: 0,
      reverse: false,
      offset: -200,
      triggerHook: 1,
    })
      .setTween(westTL)
      .addTo(westController)
    //.addIndicators()
  }

  render() {
    const { data } = this.props
    return (
      <CallOutBackgroundSection>
        <div className="co-topsec">
          <div className="co-topsec__fly">
            <FlyHookSix />
          </div>
          <div id="letter-drift-container" className="co-topsec__callout">
            <p id="letter-drift">
              <span id="letter-drift-d">D</span>
              <span id="letter-drift-r">R</span>
              <span id="letter-drift-i">I</span>
              <span id="letter-drift-f">F</span>
              <span id="letter-drift-t">T</span>
            </p>
          </div>
        </div>
        <div className="co-wrapper">
          <div className="co-content">
            <div className="co-content__title">
              <h2>{data.quote}</h2>
            </div>
            <div id="letter-out-container" className="co-background">
              <p id="letter-out">
                <span>O</span>
                <span>U</span>
                <span>T</span>
              </p>
            </div>
          </div>
        </div>

        <div className="co-herosec">
          <div id="letter-west-container" className="co-herosec__callout">
            <p id="letter-west">
              <span>W</span>
              <span>E</span>
              <span>S</span>
              <span>T</span>
            </p>
          </div>
          <div className="co-herosec__content">
            <div className="co-herosec__content--title">
              <h2>{data.heroTitle}</h2>
            </div>
            <div
              className="co-herosec__content--body"
              dangerouslySetInnerHTML={{ __html: data.heroContent }}
            />
            <div className="co-herosec__icon">
              <MedicineIcon />
            </div>
          </div>

          <ImgBg
            className="co-herosec__heroimg"
            Tag="div"
            fluid={data.heroImg.localFile.childImageSharp.fluid}
          />
        </div>
      </CallOutBackgroundSection>
    )
  }
}

export default CallOutBackground
