import React from "react"
import styled from "styled-components"

import FlyLineOne from "../elements/FlyLineOne"
import FlyLineTwo from "../elements/FlyLineTwo"
import FlyLineThree from "../elements/FlyLineThree"
import FlyLineFour from "../elements/FlyLineFour"
import FlyLineFive from "../elements/FlyLineFive"
import FlyLineSix from "../elements/FlyLineSix"

const LinePickerStyles = styled.div`
  .fly-line {
    position: absolute;
    top: 12.5rem;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }

  .fly-line-one {
    width: calc(60rem / 4);
    height: calc(54.5rem / 4);
  }

  .fly-line-two {
    top: 12.5rem;
    right: 10.5vw;
    left: auto;
    width: calc(157.6vw / 1.6);
    max-width: calc(157.6vw / 1.6);
    height: calc(44.5vw / 1.6);
    max-height: calc(44.5vw / 1.6);

    @media (min-width: 1400px) {
      top: 11.5rem;
      right: 10vw;
    }
  }

  .fly-line-three {
    top: 6rem;
    right: -15vw;
    left: auto;
    width: calc(191.5vw / 1.5);
    height: calc(135.4vw / 1.5);
    z-index: -1;

    @media (min-width: 1000px) {
      top: 5rem;
      right: -15.5vw;
      left: auto;
    }
  }

  .fly-line-four {
    width: calc(191.5vw / 1.5);
    height: calc(135.4vw / 1.5);
  }

  .fly-line-five {
    top: 3.75rem;
    right: -14vw;
    left: auto;
    width: calc(117vw / 1.5);
    height: calc(59.7vw / 1.5);
    transform: rotateX(0) rotateY(180deg);
    z-index: -1;

    @media (min-width: 800px) {
      top: 2.5rem;
      right: -19.5vw;
    }

    @media (min-width: 1000px) {
      top: 1.25rem;
      right: -21vw;
    }

    @media (min-width: 1200px) {
      top: 0.25rem;
      right: -22vw;
    }

    @media (min-width: 1400px) {
      top: -0.25rem;
      right: -23vw;
    }
  }

  .fly-line-six {
    top: 11rem;
    right: -6vw;
    left: auto;
    width: calc(163vw / 1.5);
    height: calc(40.2vw / 1.5);
    z-index: -1;

    @media (min-width: 1000px) {
      top: 11rem;
      right: -7.5vw;
    }

    @media (min-width: 1200px) {
      top: 11rem;
      right: -8vw;
    }

    @media (min-width: 1600px) {
      top: 11rem;
      right: -9vw;
    }
  }
`

const LinePicker = ({ info }) => {
  let flyIcon = false
  if (info === "one") {
    flyIcon = (
      <div className="fly-line fly-line-one">
        <FlyLineOne />
      </div>
    )
  } else if (info === "two") {
    flyIcon = (
      <div className="fly-line fly-line-two">
        <FlyLineTwo />
      </div>
    )
  } else if (info === "three") {
    flyIcon = (
      <div className="fly-line fly-line-three">
        <FlyLineThree />
      </div>
    )
  } else if (info === "four") {
    flyIcon = (
      <div className="fly-line fly-line-four">
        <FlyLineFour />
      </div>
    )
  } else if (info === "five") {
    flyIcon = (
      <div className="fly-line fly-line-five">
        <FlyLineFive />
      </div>
    )
  } else if (info === "six") {
    flyIcon = (
      <div className="fly-line fly-line-six">
        <FlyLineSix />
      </div>
    )
  }

  return <>{flyIcon && <LinePickerStyles>{flyIcon}</LinePickerStyles>}</>
}

export default LinePicker
