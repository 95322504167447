import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const FlyLineTwo = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          flyLineTwo: file(relativePath: { eq: "dow-line2.png" }) {
            childImageSharp {
              fluid(maxWidth: 1575) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Img
              fluid={data.flyLineTwo.childImageSharp.fluid}
              alt="Drift Out West Fly Fishing."
            />
          </>
        )
      }}
    />
  )
}

export default FlyLineTwo
